import { MomentInput } from 'moment'
import { DashboardTab } from '../models/dashboard-model'
import { ZergroshModuleType } from '../models/zergrosh-model'
import moment from 'moment'

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value)
}

export const resolveModuleByTab = (
  tab: DashboardTab
): ZergroshModuleType | null => {
  switch (tab) {
    case 'WELCOME_AND_GOODBYE':
    case 'WELCOME_CHANNEL':
    case 'BIRTHDAY':
    case 'LEVELS':
    case 'MUSIC':
    case 'REACTION_ROLES':
    case 'ZERGROSH_AI':
    case 'TIKTOK':
      return tab
  }

  return null
}

export const formatDateLocalizable = (
  language: string,
  input: MomentInput,
  withHours: boolean
): string => {
  if (language === 'es') {
    if (withHours) {
      return moment(input)
        .locale('es')
        .format('DD [de] MMMM [de] YYYY - hh:mma')
    } else {
      return moment(input).locale('es').format('DD [de] MMMM [de] YYYY')
    }
  } else {
    if (withHours) {
      return moment(input).locale('en').format('MMMM DD, YYYY - hh:mma')
    } else {
      return moment(input).locale('en').format('MMMM DD, YYYY')
    }
  }
}

export const generateUUID = (): string => {
  // Public Domain/MIT
  let d = new Date().getTime() //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export const emojiShortNames = (joypixels.shortnames.split('|') as string[])
  .sort((a, b) => a.localeCompare(b))
  .map((s) => s.replaceAll('\\', ''))

export const retrieveEmojiUnicodeByShortName = (shortName: string) => {
  const res = retrieveMatchingEmojis(shortName, 1, true)
  if (res.length === 0) {
    return null
  }

  return res[0].unicode
}

export const retrieveMatchingEmojis = (
  text: string,
  limit: number = 25,
  exactMatch = false
) => {
  const matched: {
    name: string
    unicode: string
  }[] = []
  for (const emoji of emojiShortNames) {
    if (
      emoji.includes('tone1') ||
      emoji.includes('tone2') ||
      emoji.includes('tone3') ||
      emoji.includes('tone4') ||
      emoji.includes('tone5') ||
      emoji.endsWith('tone:')
    ) {
      continue
    }
    if (text.trim().length === 0 || text.trim() === ':') {
      const unicode = joypixels.shortnameToUnicode(emoji)
      if (matched.find((m) => m.unicode === unicode) !== undefined) continue
      matched.push({
        name: emoji,
        unicode: unicode
      })
      if (matched.length >= limit) {
        return matched.sort((a, b) => a.name.length - b.name.length)
      }
    } else {
      if (
        (!exactMatch && emoji.includes(text)) ||
        (exactMatch && emoji.toLowerCase() === ':' + text.toLowerCase() + ':')
      ) {
        const unicode = joypixels.shortnameToUnicode(emoji)
        if (matched.find((m) => m.unicode === unicode) !== undefined) continue
        matched.push({
          name: emoji,
          unicode: unicode
        })
        if (matched.length >= limit) {
          return matched.sort((a, b) => a.name.length - b.name.length)
        }
      }
    }
  }
  return matched.sort((a, b) => a.name.length - b.name.length)
}
